import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import moment from 'moment';

import Layout from '../components/layout';
import SEO from '../components/seo';
import Recaptcha from 'react-google-recaptcha';

import undraw_newsletter from '../../static/undraw_newsletter_vovu.png';

import {
  Form,
  Input,
  Button,
  Row,
  Col,
  Divider,
  notification,
} from 'antd';

const msTeamsWebHook =
  'https://outlook.office.com/webhook/ec829691-eab8-47f1-8e65-5278c158cea4@0c0766cb-245e-4bce-b4e8-226b47bf470d/IncomingWebhook/be0055d702f64a71b34e3aa9429c7cb1/4f87b529-eda4-4001-837c-dc393ef39a98';

const StyledDiv = styled.div`
  margin-top: 64px;
  min-height: calc(100vh - 72px - 64px);
  background-color: white;
  padding-top: 3%;

  .hidden-input {
    .ant-form-item-control-input {
      min-height: 2px;
    }
  }

  .form-request {
    margin: 10px;
  }
`;

function RequestPortalAccessPage() {
  const recaptchaRef = useRef(null);

  const [isSending, setIsSending] = useState(false);

  const [form] = Form.useForm();

  return (
    <Layout>
      <SEO
        title="REQUEST ACCESS"
        description="Our portal remains invite-only for select PRIME Philippines clients, and our sales team will verify your access upon receipt of your request. Please make sure you submit complete contact information."
      />

      <StyledDiv>
        <Row justify="center">
          <Col
            xs={{ span: 23, order: 1 }}
            sm={{ span: 23, order: 1 }}
            md={{ span: 10, order: 0 }}
            lg={{ span: 10, order: 0 }}
            xl={{ span: 8, order: 0 }}
            xxl={{ span: 8, order: 0 }}
          >
            <div className="form-request">
              <h3>REQUEST ACCESS</h3>
              <p>
                Our portal remains invite-only for select PRIME Philippines
                clients, and our sales team will verify your access upon receipt
                of your request. Please make sure you submit complete contact
                information.
              </p>

              <Divider style={{ borderTop: ' 1px solid #212121' }} />

              <Form
                form={form}
                name="basic"
                layout="vertical"
                onFinish={(values) => {
                  setIsSending(true);
                  fetch(`https://hephaestus-backend.herokuapp.com/email-requests`, {
                    method: 'POST',
                    headers: {
                      Accept: 'application/json',
                      'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                      company_identifier: 'PRIMEPH-PROPWISE',
                      email_address: values.emailAddress,
                      subject: 'Propwise Inquiry (REQUEST ACCESS)',
                      data: [
                        {
                          key: 'Fullname',
                          value: `${values.complateName}`,
                        },
                        {
                          key: 'Email Address/Contact Number',
                          value: values.emailAddress,
                        },
                      ],
                    }),
                  })
                    .then((response) => response.json())
                    .then(() => {
                      notification.info({
                        message: 'Message Sent!',
                        description:
                          "We'll get back to you once receipt of your inquiry.",
                      });
                      form.resetFields();
                    })
                    .catch(() => {
                      notification.error({
                        message: 'Something Went Wrong!',
                        description:
                          'There was probleming sending your inquiry. Please try again later.',
                      });
                    })
                    .finally(() => {
                      setIsSending(false);
                    });

                  fetch(msTeamsWebHook, {
                    method: 'POST',
                    mode: 'no-cors',
                    headers: {
                      Accept: 'application/json',
                      'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                      '@context': 'https://schema.org/extensions',
                      '@type': 'MessageCard',
                      themeColor: '0072C6',
                      title: `Contact form (REQUEST ACCESS) - ${
                        values.complateName
                      } (${moment().format('LLL')})`,
                      text:
                        '* Name: ' +
                        values.complateName +
                        '\n' +
                        '* Email AddressF/Contact Number: ' +
                        values.emailAddress +
                        '\n',
                    }),
                  }).then(
                    (success) => {
                      console.log(success);
                    },
                    (failed) => {
                      console.log(failed);
                    }
                  );
                }}
              >
                <Form.Item
                  label="Complete name"
                  name="complateName"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter your complete name.',
                    },
                  ]}
                  hasFeedback
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label="Email address/Contact Number"
                  name="emailAddress"
                  rules={[
                    {
                      required: true,
                      message:
                        'Please enter your email address or contact number.',
                    },
                  ]}
                  hasFeedback
                >
                  <Input />
                </Form.Item>

                <Recaptcha
                  ref={recaptchaRef}
                  sitekey="6LdehiUTAAAAALj4VhxGS0EEMsC6aech1yN8UFfk"
                  onChange={(value) => {
                    form.setFieldsValue({
                      recaptchaToken: value,
                    });
                  }}
                />

                <Form.Item
                  name="recaptchaToken"
                  rules={[
                    {
                      required: true,
                      message:
                        'Please validate recaptcha to prove you are a human.',
                    },
                  ]}
                  className="hidden-input"
                >
                  <Input hidden disabled />
                </Form.Item>

                <br />

                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ marginRight: 8 }}
                    loading={isSending}
                  >
                    <b>SUBMIT REQUEST</b>
                  </Button>

                  <Button
                    htmlType="button"
                    onClick={() => {
                      form.resetFields();
                    }}
                  >
                    <b>RESET</b>
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </Col>
          <Col
            xs={{ span: 23, order: 0 }}
            sm={{ span: 23, order: 0 }}
            md={{ span: 9, order: 1 }}
            lg={{ span: 9, order: 1 }}
            xl={{ span: 8, order: 1 }}
            xxl={{ span: 8, order: 1 }}
          >
            <img src={undraw_newsletter} alt="" />
          </Col>
        </Row>
        <br />
      </StyledDiv>
    </Layout>
  );
}

export default RequestPortalAccessPage;
